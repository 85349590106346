import {
  HStack,
  colors,
  customEvent,
  responsive,
  kakaoPixel,
  pixelCustomEvent,
  PixelEventName,
} from "goi_common";
import { Box } from "@chakra-ui/react";
import { MAX_WIDTH } from "@/constants/components";
import { useRouter } from "next/router";
import { naverConversionEvent } from "@/utils/naverPageView";

export default function LandingFixedButton({
  text,
  color,
  href = "/registration/info/",
  eventPath = "home",
}: {
  text?: string;
  color: string;
  href?: string;
  eventPath?:
    | "home"
    | "content_sn_1"
    | "content_sn_2"
    | "feed"
    | "half"
    | "content_care"
    | "toss";
}) {
  const router = useRouter();

  return (
    <HStack
      width="100%"
      height="100%"
      justifyContent="center"
      css={{
        cursor: "pointer",
      }}
      onClick={() => {
        customEvent({
          newGtm: {
            path: eventPath,
            trigger_and_target: "CTA",
          },
        });
        customEvent({
          newGtm: {
            path: eventPath,
            trigger_and_target: "fixed_CTA",
          },
        });
        naverConversionEvent({ type: "view_product" });
        kakaoPixel()?.participation();
        pixelCustomEvent(PixelEventName.ViewContent);

        router.push(href);
      }}
    >
      <HStack
        background={color}
        width="100%"
        justifyContent="center"
        position="fixed"
        css={responsive({
          bottom: 0,
          zIndex: 10,
          height: {
            base: "76px",
            md: eventPath === "home" ? "149px" : "110px",
          },
          padding: { base: "24px", md: "44px" },
        })}
      >
        <HStack
          maxWidth={`${MAX_WIDTH}px`}
          justifyContent="center"
          width="100%"
          alignItems="center"
        >
          <Box
            css={responsive({
              fontWeight: 700,
              fontSize: {
                base: "20px",
                md: eventPath === "home" ? "48px" : "36px",
              },
            })}
            color={colors.white}
          >
            {text || "월 100원에 장례 준비하기"}
          </Box>
        </HStack>
      </HStack>
    </HStack>
  );
}
